import type { Toast } from "@/lib/session";
import { useToast } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import React, { useCallback, useContext, useEffect, useRef } from "react";

export interface ToastContextType {
  refetch: (signal?: AbortSignal) => Promise<void>;
}

export const ToastContext = React.createContext({
  refetch: () => Promise.resolve(),
});

export function useToastRefetcher() {
  const { refetch } = useContext(ToastContext);
  return refetch;
}

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const toast = useToast();
  const pathname = usePathname();
  const hasNavigated = useRef(false);

  const refetch = useCallback(
    async (signal?: AbortSignal) => {
      if (!hasNavigated.current) {
        hasNavigated.current = true;
        return;
      }

      const res = await fetch(
        new URL("/api/me/toasts", process.env.NEXT_PUBLIC_URL),
        { method: "POST", cache: "no-cache", signal },
      );

      const toasts: Toast[] = await res.json();
      for (const { type, ...rest } of toasts) {
        toast({ status: type, ...rest });
      }
    },
    [toast],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: pathname is used to trigger refetch on route change
  useEffect(() => {
    const controller = new AbortController();
    refetch(controller.signal);
    return () => controller.abort();
  }, [pathname, refetch]);

  return (
    <ToastContext.Provider value={{ refetch }}>
      {children}
    </ToastContext.Provider>
  );
}
